import '../basic'
// import { $ } from 'jquery'

window.onload = function () {
  // initializeHomeCarousel();
  initializeServiceAnimations();
  // initializeBrandCarousel();
}

// function initializeHomeCarousel() {
//   $('.carousel').slick({
//     // arrows: false
//     // prevArrow: `<button type="button" class="slick-prev" aria-label="See previous image.">
//     //               <span class="dashicons dashicons-arrow-left-alt2"></span>
//     //             </button>`,
    
//     autoplay: true,
//     autoplaySpeed: 2000,
//     prevArrow: `
//       <button type="button" class="slick-arrow--prev" aria-label="See previous image.">
//         <svg class="icon__arrow icon__arrow--left">
//           <use href="/wp-content/themes/apsilon/icons/sprite.svg#icon-chevron-left"></use>
//         </svg>
//       </button>
//     `,
//     nextArrow: `
//     <button type="button" class="slick-arrow--next" aria-label="See next image.">
//       <svg class="icon__arrow icon__arrow--right">
//         <use href="/wp-content/themes/apsilon/icons/sprite.svg#icon-chevron-right"></use>
//       </svg>
//     </button>
//   `
//     // nextArrow: `<button type="button" class="slick-next" aria-label="See next image."><span class="dashicons dashicons-arrow-right-alt2"></span></button>`,
//   });
// }

function initializeServiceAnimations() {
  const wrappers = document.querySelectorAll('.service-wrapper')
  wrappers.forEach( el => {
    el.addEventListener('mouseover', startAnimation.bind(el))
    el.addEventListener('focusin', startAnimation.bind(el))
  } )
  
  function startAnimation() {
    this.find('.service-icon-wrapper').addClass('animate')
  }


  // $('.service-wrapper').each((i, el) => $(el).mouseover(startAnimation));
  // $('.service-wrapper').each((i, el) => $(el).focusin(startAnimation));

  // $(".service-wrapper").bind("webkitAnimationEnd mozAnimationEnd animationend", function () {
  //   $(this).find('.service-icon-wrapper').removeClass("animate")
  // })

  // function startAnimation() {
  //   $(this).find('.service-icon-wrapper').addClass('animate')
  // }
}

// function initializeBrandCarousel() {
//   $('.brand-logo-carousel').slick({
//     slidesToShow: 4
//   });
// }